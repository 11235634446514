import React from "react"
import { Link } from "gatsby"

import { withBreadcrumbs } from "./withBreadcrumbs"
import { StyledBreadcrumbs, StyledBreadcrumbsContainer, StyledBreadcrumb } from "../Styled/Breadcrumb"

export const Breadcrumbs = withBreadcrumbs(
  ({ background, breadcrumb, breadcrumbs, colour, title }): JSX.Element => (
    <>
      <StyledBreadcrumbsContainer background={background} colour={colour} width={`xl`}>
        <StyledBreadcrumbs>
          <StyledBreadcrumb as={Link} title={breadcrumb?.title} to={breadcrumb?.url}>
            {breadcrumb?.title}
          </StyledBreadcrumb>
          {breadcrumbs?.map((breadcrumb: any) => (
            <StyledBreadcrumb key={breadcrumb?.title?.toString()} as={breadcrumb?.url ? Link : null} title={breadcrumb?.title} to={breadcrumb?.url}>
              {breadcrumb?.title}
            </StyledBreadcrumb>
          ))}
          <StyledBreadcrumb>{title}</StyledBreadcrumb>
        </StyledBreadcrumbs>
      </StyledBreadcrumbsContainer>
    </>
  )
)
