import React, { memo, useMemo } from "react"

import { useBreadcrumb } from "../../hooks/useBreadcrumb"
import { useRoutes } from "../../hooks/useRoutes"

export const withBreadcrumbs = Component =>
  memo(({ name = "Breadcrumbs", background = null, colour = null, parents = [], page }: any) => {
    const { breadcrumb } = useBreadcrumb()
    const { linkResolver } = useRoutes()

    const breadcrumbs = useMemo(
      () => [...parents, ...(page?.metadata?.breadcrumbs?.map((breadcrumb: any) => linkResolver(breadcrumb)) || [])],
      [linkResolver, page, parents]
    )
    const title = page?.title

    Component.displayName = name
    return useMemo(
      () => <Component background={background} breadcrumb={breadcrumb} breadcrumbs={breadcrumbs} colour={colour} title={title} />,
      [background, breadcrumb, breadcrumbs, colour, title]
    )
  })
