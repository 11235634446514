import React, { Fragment } from "react"

import { withArticleListPagination } from "./withArticleListPagination"
import { Icon } from "../../../Icon/Icon"
import { Navigation, Numbers, Pages, Items, Item, Arrow, Link } from "./styledArticleListPagination"

export const ArticleListPagination = withArticleListPagination(
  ({ currentPage, handleClick, items, locales }): JSX.Element => (
    <Navigation>
      <Items>
        <Arrow>
          <Link disabled={!currentPage} onClick={() => handleClick(currentPage - 1)} size={`large`} title={locales?.additionalPrevious}>
            <Icon name={`left`} />
          </Link>
        </Arrow>
        <Numbers>
          <Pages>
            {items?.map(({ active, hide, index, value }) => (
              <Fragment key={index?.toString()}>
                {index === items?.length - 1 &&
                  items?.length > 2 &&
                  currentPage !== 0 &&
                  currentPage !== items?.length - 1 &&
                  currentPage !== items?.length - 2 && <Item screen={`<sm`}>...</Item>}
                <Item screen={hide && `md>`}>
                  <Link active={active} colour={active ? `orange-dark` : `grey`} onClick={() => handleClick(index)} size={`large`} title={value}>
                    {value}
                  </Link>
                </Item>
                {!index && items?.length > 2 && currentPage !== 1 && <Item screen={`<sm`}>...</Item>}
              </Fragment>
            ))}
          </Pages>
        </Numbers>
        <Arrow>
          <Link
            disabled={currentPage === items?.length - 1}
            onClick={() => handleClick(currentPage + 1)}
            size={`large`}
            title={locales?.additionalNext}
          >
            <Icon name={`right`} />
          </Link>
        </Arrow>
      </Items>
    </Navigation>
  )
)
