import React from "react"

import { withArticleList } from "./withArticleList"
import { ArticleListHeader } from "./Header/ArticleListHeader"
import { ArticleListPagination } from "./Pagination/ArticleListPagination"
import { ArticleCard } from "../Card/ArticleCard"
import { StyledContainer } from "../../Styled/Container"
import { H5, P } from "../../Styled/Text"
import { Page, Articles, Article } from "./styledArticleList"

export const ArticleList = withArticleList(
  ({ articles, location, page, pages, template }): JSX.Element => (
    <Page>
      <ArticleListHeader location={location} page={page} />
      <StyledContainer width={`xlg`}>
        {articles?.length > 0 ? (
          <Articles>
            {articles?.map((article: any, index: number) => (
              <Article key={article?.id}>
                <ArticleCard article={article} large={!index || index === 3} />
              </Article>
            ))}
          </Articles>
        ) : (
          <>
            <H5 align={`center`} colour={`grey-darker`} large withSpacing={`xs`}>
              {page?.additionalEmpty}
            </H5>
            <P align={`center`} colour={`grey-darker`}>
              {page?.additionalEmptyDescription}
            </P>
          </>
        )}
        <ArticleListPagination pages={pages} template={template} />
      </StyledContainer>
    </Page>
  )
)
