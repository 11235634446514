import React from "react"

import { withArticleListHeader } from "./withArticleListHeader"
import { Breadcrumbs } from "../../../Breadcrumbs/Breadcrumbs"
import { ArticleListHeaderNavigation } from "./Navigation/ArticleListHeaderNavigation"
import { StyledContainer } from "../../../Styled/Container"
import { Title } from "./styledArticleListHeader"

export const ArticleListHeader = withArticleListHeader(
  ({ breadcrumbs, location, page, title }): JSX.Element => (
    <>
      <Breadcrumbs parents={breadcrumbs} colour={`dark`} page={page} />
      <StyledContainer width={`xlg`}>
        <Title align={`center`} colour={`purple-light`} uppercase>
          {title || page?.title}
        </Title>
        <ArticleListHeaderNavigation breadcrumbs={breadcrumbs} location={location} page={page} />
      </StyledContainer>
    </>
  )
)
