import React, { memo, useCallback, useEffect, useMemo } from "react"
import { navigate } from "gatsby"

import { useApp } from "../../../../../hooks/useApp"
import { useArticles } from "../../../../../hooks/useArticles"
import { useRoutes } from "../../../../../hooks/useRoutes"

export const withArticleListHeaderNavigation = Component =>
  memo(({ name = "ArticleListHeaderNavigation", breadcrumbs = null, location: rawLocation, page }: any) => {
    const {
      activeArticleCategory,
      activeArticlePage,
      setActiveArticleCategory,
      setActiveArticlePage,
      config: {
        settings: { params },
      },
    } = useApp()
    const { categories: rawCategories } = useArticles()
    const { getUrlParameter, setUrlParameter } = useRoutes()

    const location = useMemo(
      () => ({
        ...rawLocation,
        pathname: breadcrumbs?.[0]?.url || rawLocation?.pathname,
      }),
      [breadcrumbs, rawLocation]
    )

    const categories = useMemo(
      () =>
        rawCategories?.map((category: any) => ({
          ...category,
          active: category?.slug?.current === activeArticleCategory,
        })),
      [rawCategories, activeArticleCategory]
    )

    const handleClick = useCallback(
      (category: string) => navigate(setUrlParameter(params?.category, category, location)),
      [params, location, setUrlParameter]
    )

    useEffect(() => {
      const currentCategory = getUrlParameter(params?.category, location)

      if (activeArticleCategory !== currentCategory) {
        setActiveArticleCategory(currentCategory)
        if (activeArticlePage > 0) setActiveArticlePage(0)
      }
    }, [activeArticleCategory, activeArticlePage, params, location, getUrlParameter, setActiveArticleCategory, setActiveArticlePage])

    Component.displayName = name
    return useMemo(
      () =>
        categories?.length > 0 ? (
          <Component breadcrumbs={breadcrumbs} currentCategory={activeArticleCategory} handleClick={handleClick} items={categories} locales={page} />
        ) : null,
      [activeArticleCategory, breadcrumbs, categories, handleClick, page]
    )
  })
