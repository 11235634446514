import React from "react"

import { withArticleListHeaderNavigation } from "./withArticleListHeaderNavigation"
import { Navigation, Items, Item, Link } from "./styledArticleListHeaderNavigation"

export const ArticleListHeaderNavigation = withArticleListHeaderNavigation(
  ({ breadcrumbs, currentCategory, handleClick, items, locales }): JSX.Element => (
    <Navigation>
      <Items>
        <Item>
          <Link
            colour={!currentCategory && !breadcrumbs?.length ? `orange-dark` : `grey-darker`}
            onClick={() => handleClick(``)}
            size={`large`}
            title={locales?.additionalAll}
          >
            {locales?.additionalAll}
          </Link>
        </Item>
        {items?.map(({ active, id, slug, title }) => (
          <Item key={id?.toString()}>
            <Link colour={active ? `orange-dark` : `grey-darker`} onClick={() => handleClick(slug?.current)} size={`large`} title={title}>
              {title}
            </Link>
          </Item>
        ))}
      </Items>
    </Navigation>
  )
)
