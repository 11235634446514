import React from "react"
import { graphql } from "gatsby"

import { ArticleList as Page } from "../components/Article/List/ArticleList"

export const query = graphql`
  query {
    page: sanityPageArticles {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      additionalAll
      additionalEmpty
      additionalEmptyDescription
    }
    template: sanityTemplateArticle {
      additionalPrevious
      additionalNext
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => <Page {...props} {...data} />

export default Component
