import tw, { styled } from "twin.macro"

import { GlobalStyles } from "../../../Styled/Global"
import { StyledLink } from "../../../Styled/Button"

export const Navigation = tw.nav`mt-8 md:mt-23`
export const Items = tw.ul`flex items-center justify-center`
export const Numbers = tw.li`px-3 md:px-7.5`
export const Pages = tw.ul`flex items-center justify-center`
export const Item = styled.li`
  ${tw`block text-grey`}
  ${({ screen }) => screen && GlobalStyles.screen[screen]}
`
export const Arrow = tw(Item)`flex-shrink-0`
export const Link = styled(StyledLink)`
  ${tw`block p-2 md:p-3 md:text-2xl hover:opacity-100`}

  svg {
    ${tw`block w-4 md:w-6`}
  }
`
