import tw, { styled } from "twin.macro"

import { StyledContainer } from "./Container"
import { GlobalStyles } from "./Global"
import { Overline } from "./Text"

const styles = {
  default: tw`flex items-center`,
  align: {
    default: tw`justify-start`,
    center: tw`justify-center`,
    end: tw`justify-end`,
  },
  item: tw`block px-1.5 border-r last:border-none last:pr-0 first:pl-0 last:truncate whitespace-nowrap`,
  container: tw`absolute z-1 bg-white bg-opacity-75 md:bg-transparent py-2.5 md:py-0 top-0 md:top-6 text-grey-darker md:text-white inset-x-0`,
}

export const StyledBreadcrumbsContainer = styled(StyledContainer)`
  ${styles.container}
  ${({ prototype }) => prototype && tw`md:text-grey-darker`}
  ${({ background }) => background && GlobalStyles.background[background]}
  ${({ colour }) => colour === `dark` && tw`md:text-grey-darkest`}
`
export const StyledBreadcrumbs = styled.nav`
  ${styles.default}
  ${({ align }) => (align && styles.align[align]) || styles.align[`default`]}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
`
export const StyledBreadcrumb = styled(Overline)`
  ${styles.item}
`
