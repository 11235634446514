import React, { memo, useCallback, useMemo } from "react"

import { useApp } from "../../../../hooks/useApp"
import { useCore } from "../../../../hooks/useCore"

export const withArticleListPagination = Component =>
  memo(({ name = "ArticleListPagination", pages, template }: any) => {
    const { activeArticlePage, setActiveArticlePage } = useApp()
    const {
      helpers: { isBrowser },
    } = useCore()

    const items = Array.from(Array(pages).keys()).map((_, index) => ({
      active: activeArticlePage === index,
      index,
      hide: index && index !== pages - 1 && activeArticlePage !== index,
      value: index + 1,
    }))

    const handleClick = useCallback(
      (page: number) => {
        setActiveArticlePage(page)
        if (isBrowser) window.scrollTo({ top: 0, behavior: "smooth" })
      },
      [isBrowser, setActiveArticlePage]
    )

    Component.displayName = name
    return useMemo(
      () => (items?.length > 0 ? <Component currentPage={activeArticlePage} handleClick={handleClick} items={items} locales={template} /> : null),
      [activeArticlePage, handleClick, items, template]
    )
  })
