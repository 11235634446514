import { useStaticQuery, graphql } from "gatsby"

import { useApp } from "./useApp"
import { useRoutes } from "./useRoutes"

export const useBreadcrumb = () => {
  const { activeCollection } = useApp()
  const { linkResolver, routeResolver } = useRoutes()

  const { homepage } = useStaticQuery(graphql`
    query {
      homepage: sanityPageHomepage {
        title
      }
    }
  `)

  const breadcrumb = linkResolver(homepage, `homepage`)

  const buildBreadcrumbs = ({ item = null, paths = [] }) => {
    const regex = new RegExp(paths?.map(({ title }) => title).join(`|`), `gi`)
    const items = []

    if (item?.type === `product` && item?.collections?.find(({ title }) => title === activeCollection?.title))
      items?.push({ ...activeCollection, type: `collection` })
    paths
      .filter(({ title }) => title)
      .map(({ title, type, ...path }) => {
        items.push({ ...path, title, type, link: routeResolver({ item: path, type: type }) })
      })
    item &&
      items.push({
        ...item,
        title: item?.title?.replace(regex, ``)?.trim(),
      })

    return items
  }

  return {
    breadcrumb,
    buildBreadcrumbs,
  }
}
