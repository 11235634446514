import React, { memo, useMemo } from "react"

import { useApp } from "../../../hooks/useApp"
import { useArticles } from "../../../hooks/useArticles"

const PAGINATION = 4

export const withArticleList = Component =>
  memo(({ name = "ArticleList", location, page, template }: any) => {
    const { activeArticleCategory, activeArticlePage } = useApp()
    const { useArticlesByCategory } = useArticles()

    const allArticles = useArticlesByCategory(activeArticleCategory)
    const pages = Math.ceil(allArticles?.length / PAGINATION)
    const slice = activeArticlePage * PAGINATION
    const articles = useArticlesByCategory(activeArticleCategory)?.slice(slice, slice + PAGINATION)

    Component.displayName = name
    return useMemo(
      () => <Component articles={articles} location={location} pages={pages} page={page} template={template} />,
      [articles, location, page, pages, template]
    )
  })
